





import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "Observer",
  props: {
    options: {
      type: Object as PropType<IntersectionObserverInit | null | undefined>,
      default: () => ({})
    },
    throttle: {
      type: Number as PropType<number | null>,
      default: null
    }
  },
  data: () => ({
    observer: null as IntersectionObserver | null,
    isEnabled: true
  }),
  mounted() {
    const options = this.options ?? {};
    this.observer = new IntersectionObserver(([entry]) => {
      if (this.isEnabled && entry?.isIntersecting === true) {
        this.$emit("intersect", entry);

        // If we're throttling, disable the observer for the throttle duration
        if (this.throttle) {
          this.isEnabled = false;
          setTimeout(() => {
            if (this) {
              this.isEnabled = true;
            }
          }, this.throttle);
        }
      }
    }, options);
    this.observer.observe(this.$el);
  },
  beforeDestroy() {
    this.observer?.disconnect();
  }
});
