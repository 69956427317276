






















import Vue, { PropType } from "vue";
import { Subject, Observable, Subscription } from "rxjs";
import List from "./List.vue";

export interface Item extends Record<string, unknown> {
  id: string;
  _path: string;
}

interface ItemWatcher {
  unsubscribe: Watcher;
  lastItemRecieved: QueryDocumentSnapshot | null;
}

export default Vue.extend({
  name: "ListFirestore",
  components: {
    List
  },
  props: {
    itemName: {
      type: String,
      default: "Item"
    },
    getCollection: {
      type: Function as PropType<() => Promise<FirestoreQuery>>,
      required: true
    },
    onAddedOrUpdatedItems: {
      type: Function as PropType<((items: Dictionary<Item>) => void) | null>,
      default: null
    },
    orderList: {
      type: Function as PropType<(items: Dictionary<Item>) => Item[]>,
      required: true
    },
    limit: {
      type: Number,
      default: 20
    },
    showsEndNote: {
      type: Boolean,
      default: true
    },
    onReset: {
      type: Object as PropType<Observable<unknown>>,
      default: () => new Subject()
    },
    interactions: { type: String, default: "" }
  },
  data: () => ({
    resetSubscription: null as Subscription | null,
    loading: false,
    error: null as Error | null,
    atEnd: false,
    items: {} as Dictionary<Item>,
    listWatchers: [] as ItemWatcher[],
    lastItemRecieved: null as QueryDocumentSnapshot | null
  }),
  computed: {
    itemList(): Item[] {
      const orderedList = this.orderList(this.items);
      return orderedList;
    }
  },
  created() {
    this.resetSubscription = this.onReset.subscribe(() => this.reset());
  },
  destroyed() {
    if (this.resetSubscription) {
      this.resetSubscription.unsubscribe();
    }
    this.listWatchers.map(({ unsubscribe }) => unsubscribe());
  },
  methods: {
    reset(): void {
      console.log("reset", this.listWatchers);
      this.items = {};
      this.error = null;
      this.atEnd = false;
      this.listWatchers.forEach(({ unsubscribe }) => unsubscribe());
      this.listWatchers = [];
      this.lastItemRecieved = null;
    },
    async fetchMoreItems(): Promise<void> {
      if (!this.loading && !this.atEnd) {
        this.loading = true;
        this.error = null;
        if (this.listWatchers.some(watcher => watcher.lastItemRecieved === this.lastItemRecieved)) {
          this.loading = false;
          return;
        }
        let collection: FirestoreQuery = (await this.getCollection()).limit(this.limit ?? 20);
        if (this.lastItemRecieved !== null) {
          collection = collection.startAfter(this.lastItemRecieved);
        }
        const unsubscribe: Watcher = collection.onSnapshot(
          list => {
            this.atEnd = list.size === 0 || list.size < (this.limit ?? 20);
            const itemsToAdd: Dictionary<Item> = {};
            list.docChanges().forEach(change => {
              const itemData = change.doc;
              this.lastItemRecieved = itemData;
              if (change.type === "removed") {
                Vue.delete(this.items, itemData.id);
              } else {
                itemsToAdd[itemData.id] = {
                  ...itemData.data(),
                  id: itemData.id,
                  _path: itemData.ref.path
                };
              }
            });
            if (Object.keys(itemsToAdd).length > 0) {
              this.items = { ...this.items, ...itemsToAdd };
              if (this.onAddedOrUpdatedItems) {
                this.onAddedOrUpdatedItems(itemsToAdd);
              }
            }
            this.loading = false;
          },
          error => {
            console.error(error);
            this.error = error;
            this.loading = false;
          }
        );
        this.listWatchers.push({ unsubscribe, lastItemRecieved: this.lastItemRecieved });
      }
    }
  }
});
