





































import Vue from "vue";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import ActionButton from "@/components/ActionButton.vue";
import Observer from "@/components/Observer.vue";

export default Vue.extend({
  name: "List",
  components: {
    ActionButton,
    LoadingIndicator,
    Observer
  },
  props: {
    itemName: { type: String, default: "Item" },
    fetchMoreItems: { type: Function, required: true },
    loading: { type: Boolean, default: false },
    error: { type: Error, default: null },
    items: { type: Array, default: () => [] },
    atEnd: { type: Boolean, default: false },
    showsEndNote: { type: Boolean, default: true },
    interactions: { type: String, default: "" }
  },
  computed: {
    itemNamePlural(): string {
      return `${this.itemName}s`;
    }
  }
});
